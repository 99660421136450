//TODO:DOWNSAMPLES MUST BE REMOVED. IT IS A HACK
type SystemTags =
  | 'ATA'
  | 'ATD'
  | 'BOILER_FOC_TPD_24H'
  | 'Coord'
  | 'Current'
  | 'DestinationPort'
  | 'DGsFOC_TPD_24h'
  | 'DraftAft'
  | 'DraftFor'
  | 'EngineLoad'
  | 'ETA'
  | 'FuelLHV'
  | 'FuelType'
  | 'Heading'
  | 'IsInVoyage'
  | 'Loading'
  | 'LoadingRealtime'
  | 'LoadMargin_6h'
  | 'LoadMarginEval_6h'
  | 'ME_FOC_TPD'
  | 'ME_Power'
  | 'ME_Power_Ref_HeavyProp'
  | 'ME_Power_Ref_LightProp'
  | 'ME_RPM'
  | 'OperationalStatus'
  | 'OriginPort'
  | 'Rudder'
  | 'SOG'
  | 'SOG_Ref'
  | 'SpeedLoss_PC'
  | 'SpeedLoss_PC_6h'
  | 'SpeedLossEval_6h'
  | 'STW'
  | 'SystemHealth'
  | 'WindDirRel'
  | 'WindSpeedAbsBF'
  | 'WindSpeedRelK'
  | 'BOILER_FOC_TPD'
  | 'Cargo'
  | 'CII rating'
  | 'Course'
  | 'DataFilter'
  | 'Depth'
  | 'DGsFOC_TPD'
  | 'displacent'
  | 'DraftMidP'
  | 'DraftMidS'
  | 'ForecastAirTemperature'
  | 'ForecastAtmPressure'
  | 'ForecastCloudcover'
  | 'ForecastCurrentDir'
  | 'ForecastCurrentVelocity'
  | 'ForecastHumidity'
  | 'ForecastPercipitation'
  | 'ForecastSigWaveHeight'
  | 'ForecastVisibility'
  | 'ForecastWeatherCode'
  | 'ForecastWindDir'
  | 'ForecastWindSpeed'
  | 'FuelDensity'
  | 'Hull Cleaning Event'
  | 'Lat'
  | 'List'
  | 'LoadMargin'
  | 'LoadMarginEval'
  | 'Lon'
  | 'slip'
  | 'SpeedLossEval'
  | 'Trim'
  | 'WindSpeedRelM'
  | 'CPA_compliance'
  | 'WindSpeedAbsBF_6h'
  | 'ME_FOC_TPD_24h'
  | 'steaming_time_24h'
  | 'total_distance_24h'
  | 'CPA_compliance_6h'
  | 'isSteaming'
  | 'VoyageCompanyId'
  | 'OriginPortCode'
  | 'SOSP'
  | 'EOSP'
  | 'DestinationPortCode'
  | 'CPAspeed'
  | 'CPAspeedmarginkn'
  | 'CPAfoc'
  | 'CPAfocmarginpC'
  | 'CPAwindspeedBF'
  | 'CPAwaveheight'
  | 'nooa_wind_speed_ms'
  | 'nooa_wind_direction_deg'
  | 'nooa_wave_height_m'
  | 'nooa_wave_direction_deg';

const MapTagLabelToId: Record<SystemTags, string> = {
  ATA: '10001',
  ATD: '10002',
  BOILER_FOC_TPD_24H: '10003',
  Coord: '10004',
  Current: '10005',
  DestinationPort: '10006',
  DGsFOC_TPD_24h: '10007',
  DraftAft: '10008',
  DraftFor: '10009',
  EngineLoad: '10010',
  ETA: '10011',
  FuelLHV: '10012',
  FuelType: '10013',
  Heading: '10014',
  IsInVoyage: '10015',
  LoadingRealtime: '10016',
  LoadMargin_6h: '10017',
  LoadMarginEval_6h: '10018',
  ME_FOC_TPD: '10019',
  ME_Power: '10020',
  ME_Power_Ref_HeavyProp: '10021',
  ME_Power_Ref_LightProp: '10022',
  ME_RPM: '10023',
  OperationalStatus: '10024',
  OriginPort: '10025',
  Rudder: '10026',
  SOG: '10027',
  SOG_Ref: '10028',
  SpeedLoss_PC: '10029',
  SpeedLoss_PC_6h: '10030',
  SpeedLossEval_6h: '10031',
  STW: '10032',
  SystemHealth: '10033',
  WindDirRel: '10034',
  WindSpeedAbsBF: '10035',
  WindSpeedRelK: '10036',
  BOILER_FOC_TPD: '10037',
  Cargo: '10038',
  'CII rating': '10039',
  Course: '10040',
  DataFilter: '10041',
  Depth: '10042',
  DGsFOC_TPD: '10043',
  displacent: '10044',
  DraftMidP: '10045',
  DraftMidS: '10046',
  ForecastAirTemperature: '10047',
  ForecastAtmPressure: '10048',
  ForecastCloudcover: '10049',
  ForecastCurrentDir: '10050',
  ForecastCurrentVelocity: '10051',
  ForecastHumidity: '10052',
  ForecastPercipitation: '10053',
  ForecastSigWaveHeight: '10054',
  ForecastVisibility: '10055',
  ForecastWeatherCode: '10056',
  ForecastWindDir: '10057',
  ForecastWindSpeed: '10058',
  FuelDensity: '10059',
  'Hull Cleaning Event': '10060',
  Lat: '10061',
  List: '10062',
  LoadMargin: '10063',
  LoadMarginEval: '10064',
  Lon: '10065',
  slip: '10066',
  SpeedLossEval: '10067',
  Trim: '10068',
  WindSpeedRelM: '10069',
  WindSpeedAbsBF_6h: '10070',
  CPA_compliance: '10071',
  ME_FOC_TPD_24h: '10072',
  steaming_time_24h: '10073',
  total_distance_24h: '10074',
  CPA_compliance_6h: '10075',
  isSteaming: '10076',
  SOSP: '10077',
  EOSP: '10078',
  VoyageCompanyId: '10079',
  DestinationPortCode: '10080',
  OriginPortCode: '10081',
  CPAspeed: '10082',
  CPAspeedmarginkn: '10083',
  CPAfoc: '10084',
  CPAfocmarginpC: '10085',
  CPAwindspeedBF: '10086',
  CPAwaveheight: '10087',
  Loading: '10088',
  nooa_wind_speed_ms: '10089',
  nooa_wind_direction_deg: '10090',
  nooa_wave_height_m: '10091',
  nooa_wave_direction_deg: '10092',
} as const;

const MapTagIdToLabel: Record<string, SystemTags> = {
  '10001': 'ATA',
  '10002': 'ATD',
  '10003': 'BOILER_FOC_TPD_24H',
  '10004': 'Coord',
  '10005': 'Current',
  '10006': 'DestinationPort',
  '10007': 'DGsFOC_TPD_24h',
  '10008': 'DraftAft',
  '10009': 'DraftFor',
  '10010': 'EngineLoad',
  '10011': 'ETA',
  '10012': 'FuelLHV',
  '10013': 'FuelType',
  '10014': 'Heading',
  '10015': 'IsInVoyage',
  '10016': 'LoadingRealtime',
  '10017': 'LoadMargin_6h',
  '10018': 'LoadMarginEval_6h',
  '10019': 'ME_FOC_TPD',
  '10020': 'ME_Power',
  '10021': 'ME_Power_Ref_HeavyProp',
  '10022': 'ME_Power_Ref_LightProp',
  '10023': 'ME_RPM',
  '10024': 'OperationalStatus',
  '10025': 'OriginPort',
  '10026': 'Rudder',
  '10027': 'SOG',
  '10028': 'SOG_Ref',
  '10029': 'SpeedLoss_PC',
  '10030': 'SpeedLoss_PC_6h',
  '10031': 'SpeedLossEval_6h',
  '10032': 'STW',
  '10033': 'SystemHealth',
  '10034': 'WindDirRel',
  '10035': 'WindSpeedAbsBF',
  '10036': 'WindSpeedRelK',
  '10037': 'BOILER_FOC_TPD',
  '10038': 'Cargo',
  '10039': 'CII rating',
  '10040': 'Course',
  '10041': 'DataFilter',
  '10042': 'Depth',
  '10043': 'DGsFOC_TPD',
  '10044': 'displacent',
  '10045': 'DraftMidP',
  '10046': 'DraftMidS',
  '10047': 'ForecastAirTemperature',
  '10048': 'ForecastAtmPressure',
  '10049': 'ForecastCloudcover',
  '10050': 'ForecastCurrentDir',
  '10051': 'ForecastCurrentVelocity',
  '10052': 'ForecastHumidity',
  '10053': 'ForecastPercipitation',
  '10054': 'ForecastSigWaveHeight',
  '10055': 'ForecastVisibility',
  '10056': 'ForecastWeatherCode',
  '10057': 'ForecastWindDir',
  '10058': 'ForecastWindSpeed',
  '10059': 'FuelDensity',
  '10060': 'Hull Cleaning Event',
  '10061': 'Lat',
  '10062': 'List',
  '10063': 'LoadMargin',
  '10064': 'LoadMarginEval',
  '10065': 'Lon',
  '10066': 'slip',
  '10067': 'SpeedLossEval',
  '10068': 'Trim',
  '10069': 'WindSpeedRelM',
  '10070': 'WindSpeedAbsBF_6h',
  '10071': 'CPA_compliance',
  '10072': 'ME_FOC_TPD_24h',
  '10073': 'steaming_time_24h',
  '10074': 'total_distance_24h',
  '10075': 'CPA_compliance_6h',
  '10076': 'isSteaming',
  '10077': 'SOSP',
  '10078': 'EOSP',
  '10079': 'VoyageCompanyId',
  '10080': 'DestinationPortCode',
  '10081': 'OriginPortCode',
  '10082': 'CPAspeed',
  '10083': 'CPAspeedmarginkn',
  '10084': 'CPAfoc',
  '10085': 'CPAfocmarginpC',
  '10086': 'CPAwindspeedBF',
  '10087': 'CPAwaveheight',
  '10088': 'Loading',
  '10089': 'nooa_wind_speed_ms',
  '10090': 'nooa_wind_direction_deg',
  '10091': 'nooa_wave_height_m',
  '10092': 'nooa_wave_direction_deg',
} as const;

export { SystemTags, MapTagLabelToId, MapTagIdToLabel };
